import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import BlocConseil from "../../components/blocConseil/blocConseil";
import BlocImage from "../../components/dynamicZone/blocImage/blocImage";
import HeaderImage from "../../components/header/headerImage/headerImage";
import DirectionGeneraleGrid from "../../components/directionGeneraleGrid/directionGeneraleGrid";

import "./style.scss";

import imgMaison from "../../images/gouvernance/header.webp";
import imgBlocImage from "../../images/chiffres-cles/header.webp";

const Gouvernance = ({ location, data }) => {
  const listDirection = data.allStrapiGouvernance.nodes[0].Direction_Generale;

  const conseilAdministration = data.allStrapiGouvernance.nodes[0].Administration;
  const conseilAuxiliaireVie = data.allStrapiGouvernance.nodes[0].Conseil_Auxiliaire_Vie[0];

  return (
    <>
      <Helmet>
        <body className="gouvernance" />
      </Helmet>
      <Layout>
        <SEO title="Gouvernance" />
        <HeaderImage
          title="La Gouvernance"
          text="L’Auxiliaire est une mutuelle d’assurance. Ses membres sont appelés des sociétaires et chacun d'entre eux contribue à renforcer le niveau de protection de la structure."
          imagePath={imgMaison}
          location={location}
          crumbLabel="Gouvernance"
        />

        <section>
          <p className="gouvernance__paragraph">
            Contrairement à d’autres formes de sociétés, une mutuelle
            d’assurance{" "}
            <strong>
              n’a pas d’actionnaire et n’a donc pas de dividende à verser
            </strong>
            .
            <br />
            <br />
            Ses orientations stratégiques sont validées par un{" "}
            <strong>
              Conseil d'Administration composé essentiellement d'entrepreneurs
              du BTP
            </strong>
            . Ensemble, ils prennent toutes les décisions qu'ils jugent utiles à
            la gestion et au développement de la société.
            <br />
            <br />
            Au quotidien, L'Auxiliaire est pilotée par un{" "}
            <strong>comité de direction</strong> qui encadre ses{" "}
            <strong>250 collaborateurs</strong>.
          </p>
        </section>
        <section>
          <h2>
            Comité de <br />
            <strong>Direction</strong>
          </h2>
          <DirectionGeneraleGrid personList={listDirection} />
        </section>
        <section>
          <h2>
            Le conseil <br /> <strong>d’administration</strong> de L'Auxiliaire
          </h2>
          <BlocConseil
            president={conseilAdministration.President}
            vicePresidents={conseilAdministration.Vice_Presidents}
            secretary={conseilAdministration.Secretaire}
            listConseil={conseilAdministration.Membres}
          />
        </section>
        <section>
          <h2>
            Le conseil <strong>d’administration de L’Auxiliaire Vie</strong>
          </h2>
          <BlocConseil
            president={conseilAuxiliaireVie.President}
            vicePresidents={conseilAuxiliaireVie.Vice_Presidents}
            secretary={conseilAuxiliaireVie.Secretaires}
            listConseil={conseilAuxiliaireVie.Membres}
          />
        </section>
        <BlocImage
          title="Vous souhaitez en <br><strong>savoir + sur nous&nbsp;?</strong>"
          btnText="Nos infos et chiffres clés"
          imagePath={imgBlocImage}
          btnUrl="/chiffres-cles"
        />
      </Layout>
    </>
  );
};

export const query = graphql`
  query GouvernancePageQuery {
    allStrapiGouvernance {
      nodes {
        Direction_Generale {
          Nom
          Poste
          Prenom
          Photo {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }

        Administration {
          Membres {
            Nom
            Poste
            Region
          }
          President {
            Nom
            Departement
            Prenom
            Photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          Secretaire {
            Nom
            Departement
            Prenom
            Photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          Vice_Presidents {
            Nom
            Prenom
            Photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }

        Conseil_Auxiliaire_Vie {
          Membres {
            Nom
            Poste
            Region
          }
          President {
            Nom
            Departement
            Prenom
            Photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          Secretaire {
            Nom
            Departement
            Prenom
            Photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          Vice_Presidents {
            Nom
            Prenom
            Photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Gouvernance;
