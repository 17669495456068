import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./conseilCard.scss";

export default function ConseilCard({ fullName, title, department, photo }) {
  const image = getImage(photo.localFile);
  return (
    <div className="conseil-card">
      <GatsbyImage image={image} alt={`photo de ${fullName}`} />
      <div className="conseil-card__text">
        <span className="conseil-card__name">{fullName}</span>
        <span className="conseil-card__title">{title}</span>
        <span className="conseil-card__department">{department}</span>
      </div>
    </div>
  );
}
