import React from "react";

import PersonCard from "../card/personCard/personCard";

import "./directionGeneraleGrid.scss";

export default function DirectionGeneraleGrid({ personList }) {
  const list = personList;
  return (
    <section className="direction-generale-grid">
      <div className="direction-generale-grid__top">
        {list.slice(0,3).map((person, i) => {
          return (
            <PersonCard
              key={i}
              person={person}
              className="direction-generale-grid__person-card"
            />
          );
        })}
      </div>
      <div className="direction-generale-grid__bottom">
        {list.slice(3).map((person, i) => {
          return (
            <PersonCard
              key={i}
              person={person}
              className="direction-generale-grid__person-card"
            />
          );
        })}
      </div>
    </section>
  );
}
