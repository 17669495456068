import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./personCard.scss";

export default function PersonCard({ person, className, gridArea }) {
  const photo = person?.Photo?.localFile
    ? getImage(person.Photo.localFile)
    : person.Photo;
  return (
    <div
      className={`person-card ${className || ""}`}
      style={{
        gridArea: gridArea
      }}
    >
      {person?.Photo?.localFile ? (
        <GatsbyImage
          image={photo}
          alt={`photo de ${person.Prenom} ${person.Nom}`}
        />
      ) : (
        <img src={photo} alt={`photo de ${person.Prenom} ${person.Nom}`} width={500} height={500}/>
      )}
      <div className="person-card__text-container">
        <div className="person-card__name">{person.Prenom}</div>
        <div className="person-card__surname">{person.Nom}</div>
        <small className="person-card__title">{person.Poste}</small>
      </div>
    </div>
  );
}
